import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link } from "react-router-dom";
import './listacliente.css';
import { getFirestore, collection, getDocs, where, query } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthContext } from '../Acesso/Context/auth';

function ListaCliente(props) {
  const [filtroDataVenda01, setFiltroDataVenda01] = useState(localStorage.getItem('filtroDataVenda01') || "");
  const [filtroDataVenda02, setFiltroDataVenda02] = useState(localStorage.getItem('filtroDataVenda02') || "");
  const [quantidadeClientes, setQuantidadeClientes] = useState(0);
  const [quantidadePagos, setQuantidadePagos] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    localStorage.setItem('filtroDataVenda01', filtroDataVenda01);
    localStorage.setItem('filtroDataVenda02', filtroDataVenda02);
  }, [filtroDataVenda01, filtroDataVenda02]);

  const sortedClientes = React.useMemo(() => {
    return props.arrayClientes.slice().sort((a, b) => new Date(b.data) - new Date(a.data));
  }, [props.arrayClientes]);

  const [exclusiveUser, setExclusiveUser] = useState(false)
  const { setLogado } = useContext(AuthContext);
  const auth = getAuth();
  const [isAdmUser, setIsAdmUser] = useState(false);

  const formattedClientes = React.useMemo(() => {
    return sortedClientes.map(cliente => {
      return {
        ...cliente,
        formattedData01: formatarData(cliente.data),
        formattedData02: formatarData(cliente.venc2)
      };
    });
  }, [sortedClientes]);

  function formatarData(data) {
    if (typeof data === 'string' && data.includes('-')) {
      const partes = data.split('-');
      return `${partes[2]}-${partes[1]}-${partes[0]}`;
    } else {
      return 'N/A';
    }
  }

  const handleVerificarPagos = useCallback(async () => {
    try {
      const db = getFirestore();
      const userId = auth.currentUser?.uid;
      if (userId === 'yezea9eucLS9O1Pyl1LDzGXNTkE2') {
        setIsAdmUser(true)
      }
      else if (userId === 'yezea9eucLS9O1Pyl1LDzGXNTkE2') {
        setExclusiveUser(true)
      }
      let q;
      const userAllViwer = ((userId === 'JErLzWpMaDhnK7FQCNyWxovFGF92') || (userId === 'Hk5ute6UesQM6R438MyBu6Cc9TF2') || (userId === 'W4OmQKw6gWTnWioUENmEpPjwb4m1') || (userId === 'JiQlIYtqE6X4cuhAefF655384L42') || (userId === 'yezea9eucLS9O1Pyl1LDzGXNTkE2') || (userId === 'aWFWUvSEOxYmBBsJiTZR7KLD2X23') || (userId === '3RmT5lBN8bhHt6pdHyOq9oBW6yD3') || (userId === 'fzPJ8yp4OJPAvGcBXP0aVD0TYe62'))
      if (userAllViwer) {
        q = query(collection(db, 'clientes'));
      } else {
        q = query(collection(db, 'clientes'), where('userId', '==', userId));
      }
      const querySnapshot = await getDocs(q);
      const listaCli = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        fantasia: doc.data().fantasia,
        cpf: doc.data().cpf,
        nome: doc.data().nome,
        email: doc.data().email,
        fone: doc.data().fone,
        data: doc.data().data,
        razao: doc.data().razao,
        venc: doc.data().venc
      }));

      setQuantidadeClientes(listaCli.length);
      setTotalPages(Math.ceil(listaCli.length / 10));
    } catch (error) {
      console.error('Erro ao obter dados:', error);
    }
  }, [auth]);

  const Logout = () => {
    setLogado(false);
    localStorage.removeItem('logado');
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLogado(true);
        handleVerificarPagos();
      } else {
        setLogado(false);
      }
    });
    return () => unsubscribe();
  }, [auth, setLogado, handleVerificarPagos]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
    window.scrollTo(0, 0);
  };

  const clientesPerPage = 10;
  const filteredClientes = formattedClientes.filter((cliente) =>
    (!filtroDataVenda01 || cliente.data === filtroDataVenda01) &&
    (!filtroDataVenda02 || cliente.venc2 === filtroDataVenda02)
  );

  const indexOfLastCliente = currentPage * clientesPerPage;
  const indexOfFirstCliente = indexOfLastCliente - clientesPerPage;
  const currentClientes = filteredClientes.slice(indexOfFirstCliente, indexOfLastCliente);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredClientes.length / clientesPerPage));
  }, [filteredClientes]);

  return (
    <div>
      <div className="divAss">
        <div className="divDate">
          <p className="text-center">DATA DE VENCIMENTO</p>
          <input
            type="date"
            value={filtroDataVenda01}
            onChange={(e) => setFiltroDataVenda01(e.target.value)}
            className="form-control date date-config"
          />
        </div>
      </div>
      <table className="table table-hover table-bordered">
        <thead>
          <tr className="table-primari">
            <th scope="col" className="col-acao text-center">CNPJ/CPF</th>
            <th scope="col" className="col-acao text-center">NOME</th>
            <th scope="col" className="col-acao text-center">E-MAIL</th>
            <th scope="col" className="col-acao text-center">OPERADOR</th>
            <th scope="col" className="col-acao text-center">VENCIMENTO</th>
            <th scope="col" className="col-acao text-center"><i className="fa-solid fa-clipboard icon-u"></i></th>
          </tr>
        </thead>
        <tbody>
          {currentClientes.map((cliente) => (
            <tr key={cliente.id} className="table-light">
              <th scope="row" className="align-middle">
                <Link to={`/app/home/fichacliente/${cliente.id}`} className="fa-solid fa-list icone-acao1 align-middle"></Link>{cliente.cpf}
              </th>
              <td className="align-middle text-center ">{cliente.nome || 'N/A'}</td>
              <td className="align-middle text-center ">{cliente.email || 'N/A'}</td>
              <td className="align-middle text-center ">{cliente.operador || 'N/A'}</td>
              <td className="align-middle text-center ">{cliente.formattedData01}</td>
              <td className="align-middle text-center ">
                <Link to={`/app/home/editarcliente/${cliente.id}`}><i className="fa-solid fa-pen-to-square icone-acao"></i></Link>
                <Link to="#" onClick={() => props.clickDelete(cliente.id)}><i className="fa-solid fa-trash icone-acao red"></i></Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="row">
        <div className="col-12 text-center">
          <div className="pagination">
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </button>
            <div className="pagination text-light">
              <p>{currentPage}</p>
              <p>-</p>
              <p>{totalPages}</p>
            </div>
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Próximo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListaCliente;
